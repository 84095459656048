import differenceInYears from "date-fns/differenceInYears"
import format from "date-fns/format"
import formatRelative from "date-fns/formatRelative"
import set from "date-fns/set"

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "June",
  "July", "Aug", "Sept", "Oct", "Nov", "Dec"
]

export const DATE_FORMAT = import.meta.env.VITE_APP_DATE_FORMAT ?? "MM/dd/yyyy"

export const formatDate = (timestamp: number | string, formatString: string = "d MMM yyyy") => {
  try {
    const date: Date = new Date(timestamp)
    return format(date, formatString)
  } catch (ex) {
    return ""
  }
}

export const customDateFormat = (timestamp: string, formatString: "d MMM yyyy" | "MMM yy" = "d MMM yyyy") => {
  const formatStringMonthIndex = formatString.split(' ').indexOf("MMM")
  const date = new Date(timestamp)
  const d = format(date, formatString).split(' ')
  const formatDate = [...d]
  formatDate.splice(formatStringMonthIndex, 1, months[date.getMonth()])

  return formatDate.join(' ')
}

export const formatDateShort = (timestamp: number | string) => {
  return formatDate(timestamp)
}

export const formatDateLong = (timestamp: number | string) => {
  return formatDate(timestamp, "dd MMMM yyyy")
}

export const formatDateRelative = (timestamp: number | string) => {
  const date: Date = new Date(timestamp)
  const currentDate: Date = new Date()
  return formatRelative(date, currentDate)
}

export function addYears(date: Date, years: number): Date {
  const result = new Date(date)
  result.setFullYear(date.getFullYear() + years)
  return result
}

export function subtractYears(date: Date, years: number): Date {
  const result = new Date(date)
  result.setFullYear(date.getFullYear() - years)
  return result
}

export const range = (startYear: number, totalYears: number): ReadonlyArray<number> => {
  return Array.from(Array(totalYears).keys()).map((i) => i + startYear)
}

export const getAge = (dob: string) => {
  return differenceInYears(
    set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
    set(new Date(dob), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  )
}