import clsx from "clsx"
import { Link } from "react-router-dom"
import bgImage from "./assets/hero.png"
import logoImage from "./assets/cp-logo.svg"
import { FirmContext } from "../../../../src/contexts/FirmContext"
import { useContext } from "react"

const RMJOverviewPage = () => {
  const { firm } = useContext(FirmContext)
  return (
    <div className="fixed inset-0 flex flex-col w-screen items-center h-auto min-h-full">
      <img alt="" className="antialiased absolute w-full h-full object-cover" src={bgImage} />
      <div className="relative w-full grow xl-max:max-w-screen-lg flex justify-center z-10">
        <div className="grid items-center grid-cols-12 gap-5 my-8">
          <div className="col-span-6 xl-max:col-span-6 col-start-6 xl-max:col-start-7">
            <div className="shadow bg-white p-10">
              <h2 className="text-sec text-main-600 tracking-tight mb-2 uppercase">Today's agenda</h2>
              <h3 className="text-h1 text-main-600 font-semibold mb-10">Your comfort with investment risk</h3>
              <ul className="mb-10">
                {[
                  "Discuss different dimensions of risk",
                  "Review your decisions in the activity",
                  "Reveal your Attitude to Risk",
                  "Discuss your Sensitivity to Loss",
                  "Explore your Risk Comfort"
                ].map((val) => (
                  <li
                    className={clsx(
                      "relative flex items-center text-main-500 text-p pb-5 last-of-type:pb-0",
                      "before:content-[''] before:mr-6 before:block before:rounded-full before:w-1.5 before:h-1.5 before:bg-surface-400",
                      "after:content-[''] after:absolute after:left-0 after:top-0 after:block after:last-of-type:hidden after:w-[1px] after:h-full after:mt-3 after:ml-[3px] after:bg-surface-300"
                    )}
                    key={val}
                  >
                    {val}
                  </li>
                ))}
              </ul>
              <Link to="../advice-overview" className="btn btn-primary btn-medium mt-4 md:px-20">
                Begin
              </Link>
            </div>
          </div>
        </div>
      </div>
      <footer className={clsx("bg-white flex absolute p-5 items-center w-full bottom-0 left-0 h-[70px]")}>
        <div className="absolute left-0 p-5">{firm?.logo ? <img src={firm?.logo} alt={`${firm?.name} logo`} className=" h-8" /> : <div />}</div>
        <div className="absolute right-0 p-5">
          <img className="h-4" src={logoImage} alt="Powered by Capital Preferences" />
        </div>
      </footer>
    </div>
  )
}

export default RMJOverviewPage
