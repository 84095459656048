import axios from "axios"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import Loading from "../../components/ClientProfile/Loading/Loading"
import { useTheme } from "../../contexts/ThemeContext"
import { GameType } from "../../models/Client"
import { DATE_FORMAT, formatDate } from "../../lib/date"
import shacharImg from "./assets/shachar.png"
import advisorImg from "./assets/tmp-advisor.png"
import thumbnailImg from "./assets/thumbnail-preferences.png"
import video from "./assets/stated-vs-revealed-preferences.mp4"
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer"
import CPLogo from "../../layout/images/CPLogo.svg"
import { tt } from "../../lib/translations"
import checkmark from "../../assets/icons/checkmark-circle-fill.svg"
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage"
import FingerprintProgressBar from "./components/FingerprintProgressBar"
import { clsx } from "clsx"

type ClientFingerprintGame = {
  id: string
  gameType: GameType
  createdAt: string
  playedAt: string
  url: string
}

type ClientFingerprint = {
  id: string
  firm: {
    _id: string
    logo: string
    name: string
  }
  firstName: string
  lastName: string
  games: ClientFingerprintGame[]
}

export const EconomicFingerprintPage = () => {
  const { uuid } = useParams()
  const theme = useTheme()

  const resolved = useQuery<ClientFingerprint>(["game-status", uuid], () =>
    axios(`${import.meta.env.VITE_APP_API_BASE || ""}/cp24/api/v4/game/${uuid}/status`).then((res) => res.data)
  )

  const gamesOrder = [
    {
      id: "finances",
      heading: "Understanding your financial picture",
      types: ["pfs", "goals"]
    },
    {
      id: "expectations",
      heading: "Personalising your service experience",
      types: ["servicing"]
    },
    {
      id: "preferences",
      heading: "Exploring preferences that make you unique",
      types: ["risk", "liquidity", "esg", "retirement", "tax"]
    }
  ]

  const percentageComplete = ((resolved.data?.games.filter((game) => game.playedAt).length || 0) / (resolved.data?.games.length || 1)) * 100
  const fillPercentage = (percentageComplete / 100) * 75

  const GameCard = ({ game, color, icon }: { game: ClientFingerprintGame; color: string; icon: string }) => (
    <div
      key={game.id}
      className={clsx(
        "bg-white relative flex items-center p-4 border border-interactive-200",
        !game.playedAt && "hover:bg-interactive-100 active:bg-interactive-200"
      )}
      style={{ borderLeft: `3px solid ${color}` }}
    >
      {!game.playedAt && <a href={game.url} target="_blank" rel="noreferrer" className="absolute w-full h-full" />}
      <div className="flex items-center gap-2 w-full">
        <img src={icon} alt="Game logo" />
        <div className="flex flex-col md:flex-row md:justify-between gap-1 w-full">
          <h3 className="font-semibold text-sec leading-4 text-main-600">{tt({ id: `game-${game.gameType}-title` })}</h3>
          <p className="text-sm text-main-400 leading-4 mr-6">
            {!game.playedAt && `Invited on ${formatDate(game.createdAt, DATE_FORMAT)}`}
            {game.playedAt && `Completed on ${formatDate(game.playedAt, DATE_FORMAT)}`}
          </p>
        </div>
      </div>
      {game.playedAt ? <img src={checkmark} className="w-4 h-4" alt="Checkmark" /> : <div className="w-4 h-4 rounded-full bg-surface-activity" />}
    </div>
  )

  return resolved.isError ? (
    <ErrorMessage />
  ) : (
    <div className="w-full h-full">
      {resolved.isLoading && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <Loading />
        </div>
      )}
      {resolved.isSuccess && (
        <div className="text-main-500">
          <div className="flex justify-between items-center bg-white h-8 px-4">
            {resolved.data.firm.logo ? (
              <img alt={`${resolved.data.firm.name} logo`} className="my-auto pr-5 h-[18px]" src={resolved.data.firm.logo} />
            ) : (
              <p className="my-auto pr-5">{resolved.data.firm.name}</p>
            )}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col items-center gap-6 bg-alt-surface py-10 px-4">
              <h1 className="text-h1 text-white font-semibold text-center">
                <span>Your Economic</span>
                <span className="block text-highlight-300 relative">
                  Fingerprint
                  <sup className="text-xs absolute top-2">TM</sup>
                </span>
              </h1>
              <FingerprintProgressBar fillPercentage={fillPercentage} />
              <p className="text-sec font-semibold text-white flex gap-2">
                <span>{resolved.data.firstName}</span>
                <span className="text-highlight-300">{percentageComplete.toFixed(0)}% completed</span>
              </p>
            </div>
            <div className="flex flex-col items-center py-10">
              <div className="flex flex-col items-center gap-6 max-w-screen-md mx-4">
                <p>
                  There's nothing more important and fundamental than knowing you as an individual. Please complete these activities, so that we know you best.
                </p>
                <div className="w-full flex flex-col gap-6">
                  {gamesOrder
                    .filter((group) => resolved.data.games.some((game) => group.types.includes(game.gameType)))
                    .map((group) => (
                      <div key={group.id} className="flex flex-col gap-2">
                        <h2 className="text-sec font-semibold text-main-600">{group.heading}</h2>
                        {group.types
                          .filter((type) => resolved.data.games.some((game) => game.gameType === type))
                          .map((type) =>
                            resolved.data.games
                              .filter((game) => game.gameType === type)
                              .map((game) => (
                                <GameCard
                                  key={game.id}
                                  game={game}
                                  color={theme.games.find((g) => g.type === game.gameType)!.color}
                                  icon={theme.games.find((g) => g.type === game.gameType)!.secondaryLogo}
                                />
                              ))
                          )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center bg-surface-200 py-10">
              <div className="flex flex-col items-center gap-6 max-w-screen-md mx-4">
                <h2 className="text-h3 font-semibold text-main-600">Supported by science</h2>
                <div className="flex flex-col gap-2">
                  <p>Our advice team cares deeply about understanding your unique goals, needs, and preferences.</p>
                  <p>We use the Economic Fingerprint™ to get a clear understanding of what's important to you and your family.</p>
                </div>
                <div className="w-full">
                  <hr className="w-full text-surface-500" />
                  <div className="flex gap-4 items-center py-3">
                    <img src={advisorImg} />
                    <div className="flex flex-col gap-1">
                      <p className="text-sec font-semibold">John Smith</p>
                      <p className="text-sm text-main-400">Your financial advisor at {resolved.data.firm.name}</p>
                    </div>
                  </div>
                  <hr className="w-full text-surface-500" />
                  <div className="flex gap-4 items-center py-3">
                    <img src={shacharImg} />
                    <div className="flex flex-col gap-1">
                      <p className="text-sec font-semibold">Shachar Kariv, PhD</p>
                      <p className="text-sm text-main-400 md:max-w-[70%]">
                        Professor of Economics at University of California, Berkeley and Co-founder of Capital Preferences.
                      </p>
                    </div>
                  </div>
                  <hr className="w-full text-surface-500" />
                </div>
                <p>
                  These activities are based on decades of internationally acclaimed Decision Science and Behavioral Economics research to calculate your
                  Economic Fingerprint™.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-6 bg-white py-10">
              <div className="flex flex-col items-center text-center gap-6 mx-4">
                <h2 className="text-h3 font-semibold">We value your uniqueness</h2>
                <VideoPlayer
                  message={<span className="text-white font-semibold">The science of understanding you</span>}
                  thumbnail={thumbnailImg}
                  videoSrc={video}
                />
                <img src={CPLogo} className="h-3.5" alt="Powered by Capital Preferences" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
