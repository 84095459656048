import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router"
import axiosInstance from "../api/axiosInstance"
import Loading from "../components/ClientProfile/Loading/Loading"
import { useState } from "react"

const RedirectExternalClient = () => {
  const { provider, externalId } = useParams()
  const navigate = useNavigate()
  const [error, setError] = useState("")
  
  const resolve = useQuery<{ clientId?: string, householdId?: string }>(
    ["client:external", provider, externalId],
    () => axiosInstance.get(`${import.meta.env.VITE_APP_API_BASE}/api/crm/${provider}/client/${externalId}`).then(res => res.data),
    {
      enabled: true,
      retry: false,
      onSuccess: ({ clientId, householdId }) => {
        if (clientId) {
          navigate("/clients/" + clientId)
        } else if (householdId) {
          navigate("/households/" + householdId)
        } else {
          setError("Something went wrong")
        }
      }
    }
  )
  
  
  return (
    <div className="full-flex-content-center">
      {resolve.isLoading && <Loading />}
      <div className="text-error">{error}</div>
    </div>
  )
}

export default RedirectExternalClient